import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  
  { // 首页
    path: '/',
    mode:'history',
    base:'/vue/',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home/home.vue')
      }, 
      { 
        // 精品课程
        // web前端开发
        path: '/ExcellentCourse/1.html',
        name: 'ExcellentCourse1',
        component: () => import('@/views/excellentCourse/ExcellentCourse1.vue')
      }, 
      { 
        // 精品课程
        // 大数据开发
        path: '/ExcellentCourse/2.html',
        name: 'ExcellentCourse2',
        component: () => import('@/views/excellentCourse/ExcellentCourse2.vue')
      }, 
      { 
        // 精品课程
        // 跨境电商
        path: '/ExcellentCourse/3.html',
        name: 'ExcellentCourse3',
        component: () => import('@/views/excellentCourse/ExcellentCourse3.vue')
      }, 
      { 
        // 精品课程
        // 软件测试
        path: '/ExcellentCourse/4.html',
        name: 'ExcellentCourse4',
        component: () => import('@/views/excellentCourse/ExcellentCourse4.vue')
      }, 
      { 
        // 精品课程
        // 软件开发
        path: '/ExcellentCourse/5.html',
        name: 'ExcellentCourse5',
        component: () => import('@/views/excellentCourse/ExcellentCourse5.vue')
      }, 
      { 
        // 精品课程
        // 视觉设计
        path: '/ExcellentCourse/6.html',
        name: 'ExcellentCourse6',
        component: () => import('@/views/excellentCourse/ExcellentCourse6.vue')
      }, 
      { 
        // 精品课程
        // 虚拟现实（VR）
        path: '/ExcellentCourse/7.html',
        name: 'ExcellentCourse7',
        component: () => import('@/views/excellentCourse/ExcellentCourse7.vue')
      }, 
      { 
        // 精品课程
        // 移动智能开发
        path: '/ExcellentCourse/8.html',
        name: 'ExcellentCourse8',
        component: () => import('@/views/excellentCourse/ExcellentCourse8.vue')
      }, 
      { // 新闻资讯
        path: '/NewsInformation',
        name: 'NewsInformation',
        component: () => import('@/views/newsInformation/NewsInformation.vue')
      },
      {
        // 就业服务
        path: '/Employment',
        name: 'Employment',
        component: () => import('@/views/employment/Employment.vue')
      },
      {
        // 技术问答
        path: '/Answer',
        name: 'Answer',
        component: () => import('@/views/answer/Answer.vue')
      },
      {
        // 关于我们
        path: '/About',
        name: 'About',
        component: () => import('@/views/about/About.vue')
      },
      {
        // 技术问答答案
        path:'/daan1',
        name:'Result1',
        component: () => import('@/views/answer/Result1.vue'),
      },
      {
        // 技术问答答案
        path:'/daan2',
        name:'Result2',
        component: () => import('@/views/answer/Result2.vue'),
      },
      {
        // 技术问答答案
        path:'/daan3',
        name:'Result3',
        component: () => import('@/views/answer/Result3.vue'),
      },
      {
        // 技术问答答案
        path:'/daan4',
        name:'Result4',
        component: () => import('@/views/answer/Result4.vue'),
      },
      {
        // 技术问答答案
        path:'/daan5',
        name:'Result5',
        component: () => import('@/views/answer/Result5.vue'),
      },
      {
        // 技术问答答案
        path:'/daan6',
        name:'Result6',
        component: () => import('@/views/answer/Result6.vue'),
      },
      {
        // 技术问答答案
        path:'/daan7',
        name:'Result7',
        component: () => import('@/views/answer/Result7.vue'),
      },
      {
        // 技术问答答案
        path:'/daan8',
        name:'Result8',
        component: () => import('@/views/answer/Result8.vue'),
      },
      {
        // 技术问答答案
        path:'/daan9',
        name:'Result9',
        component: () => import('@/views/answer/Result9.vue'),
      },
      {
        // 技术问答答案
        path:'/daan10',
        name:'Result10',
        component: () => import('@/views/answer/Result10.vue'),
      },
      {
        // 免费课程
        path: '/Gratis',
        name: 'Gratis',
        component: () => import('@/views/gratis/Gratis.vue')
      },
      { // 新闻动态
        path: '/NewsTrends',
        name: 'NewsTrends',
        component: () => import('@/views/newsInformation/NewsTrends.vue')
      },
      {
        // 教研团队
        path: '/teacherTeam',
        name: 'teacherTeam',
        component: () => import('@/views/teacher/teacherTeam.vue')
      },
      {
        // 教师详情页
        path: '/teacherDetails',
        name: 'teacherDetails',
        component: () => import('@/views/teacher/teacherDetails.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年7月12日
        // 超燃开战，等你来创！——第九届 “互联网+” 双创大赛校赛路演名单公布
        path: '/NewsTrends/1.html',
        name: 'NewsTrends1',
        component: () => import('@/views/newsInformation/NewsTrends1.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年6月22日
        // 浓情端午，粽香校园——北京工商管理专修学院师生欢度佳节
        path: '/NewsTrends/2.html',
        name: 'NewsTrends2',
        component: () => import('@/views/newsInformation/NewsTrends2.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年6月12日
        // 北京工商管理专修学院靠谱吗？超实用的学校选择技巧！
        path: '/NewsTrends/3.html',
        name: 'NewsTrends3',
        component: () => import('@/views/newsInformation/NewsTrends3.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年6月20日
        // 落榜≠失败，来北工商学数字技术，突破就业决赛圈
        path: '/NewsTrends/4.html',
        name: 'NewsTrends4',
        component: () => import('@/views/newsInformation/NewsTrends4.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年6月10日
        // 重磅！北京市发布《互联网 3.0 创新发展白皮书》，学习互联网数字技术未来可期！
        path: '/NewsTrends/5.html',
        name: 'NewsTrends5',
        component: () => import('@/views/newsInformation/NewsTrends5.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年6月7日
        // 2023高考加油，择校必看！如何在考生中突围！
        path: '/NewsTrends/6.html',
        name: 'NewsTrends6',
        component: () => import('@/views/newsInformation/NewsTrends6.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年6月6日
        // @初三同学 | 中考进入倒计时！与其被迫分流，不如提前择校早做规划！
        path: '/NewsTrends/7.html',
        name: 'NewsTrends7',
        component: () => import('@/views/newsInformation/NewsTrends7.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年5月8日
        // 《德育报》刊发我校特色办学之路：培养数字化技术人才，助力教育高质量发展
        path: '/NewsTrends/8.html',
        name: 'NewsTrends8',
        component: () => import('@/views/newsInformation/NewsTrends8.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年4月28日
        // 与北工商一起向未来|高质量充分就业，给你稳稳的幸福
        path: '/NewsTrends/9.html',
        name: 'NewsTrends9',
        component: () => import('@/views/newsInformation/NewsTrends9.vue')
      },
      {
        // 新闻资讯单页面
        // 新闻动态
        // 2023年4月17日
        // 2023届毕业生达1158万，就业堪称地狱，如何破局？
        path: '/NewsTrends/10.html',
        name: 'NewsTrends10',
        component: () => import('@/views/newsInformation/NewsTrends10.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年7月3日
        // 北京工商管理专修学院学费贵不贵，学多久？学费花在哪里？
        path: '/NewsTrends/11.html',
        name: 'NewsTrends11',
        component: () => import('@/views/newsInformation/NewsTrends11.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年7月14日
        // 看了大学生就业现状，才明白为什么那么多人都去北工商学IT
        path: '/NewsTrends/12.html',
        name: 'NewsTrends12',
        component: () => import('@/views/newsInformation/NewsTrends12.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年7月8日
        // 大数据培训，选哪个机构靠谱点
        path: '/NewsTrends/13.html',
        name: 'NewsTrends13',
        component: () => import('@/views/newsInformation/NewsTrends13.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年7月3日
        // Java培训学习必须要去一线大城市吗
        path: '/NewsTrends/14.html',
        name: 'NewsTrends14',
        component: () => import('@/views/newsInformation/NewsTrends14.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年6月28日
        // 想转行做大数据行业需要什么条件
        path: '/NewsTrends/15.html',
        name: 'NewsTrends15',
        component: () => import('@/views/newsInformation/NewsTrends15.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年6月15日
        // web前端应该怎么样，学完就业方向有哪些
        path: '/NewsTrends/16.html',
        name: 'NewsTrends16',
        component: () => import('@/views/newsInformation/NewsTrends16.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年5月30日
        // 什么是VR？VR能给我们带来什么？
        path: '/NewsTrends/17.html',
        name: 'NewsTrends17',
        component: () => import('@/views/newsInformation/NewsTrends17.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年5月21日
        // ChatGPT风靡全球，计算机专业将会持续大热门！
        path: '/NewsTrends/18.html',
        name: 'NewsTrends18',
        component: () => import('@/views/newsInformation/NewsTrends18.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年5月19日
        // 挥洒艺术，设计未来，选择一个具有创造力的专业！
        path: '/NewsTrends/19.html',
        name: 'NewsTrends19',
        component: () => import('@/views/newsInformation/NewsTrends19.vue')
      },
      {
        // 新闻资讯单页面
        // 行业资讯
        // 2023年4月30日
        // 大数据技术都在哪些应用领域比较常见
        path: '/NewsTrends/20.html',
        name: 'NewsTrends20',
        component: () => import('@/views/newsInformation/NewsTrends20.vue')
      },
      // 免费课程单页面（暂时不用）
      {
        path: '/EmpClass',
        name: 'EmpClass',
        component: () => import('@/views/gratis/EmpClass.vue')
      },
      {
        // 全部新闻页面
        path: '/allNewsTrends',
        name: 'allNewsTrends',
        component: () => import('@/views/newsInformation/AllNewsTrends.vue')
      },
      // 免费课程单页面1
      {
        path: '/Gratis1',
        name: 'Gratis1',
        component: () => import('@/views/gratis/Gratis1.vue')
      },
      // 免费课程单页面2
      {
        path: '/Gratis2',
        name: 'Gratis2',
        component: () => import('@/views/gratis/Gratis2.vue')
      },
      // 免费课程单页面3
      {
        path: '/Gratis3',
        name: 'Gratis3',
        component: () => import('@/views/gratis/Gratis3.vue')
      },
      // 免费课程单页面4
      {
        path: '/Gratis4',
        name: 'Gratis4',
        component: () => import('@/views/gratis/Gratis4.vue')
      },
      // 免费课程单页面5
      {
        path: '/Gratis5',
        name: 'Gratis5',
        component: () => import('@/views/gratis/Gratis5.vue')
      },
      // 免费课程单页面6
      {
        path: '/Gratis6',
        name: 'Gratis6',
        component: () => import('@/views/gratis/Gratis6.vue')
      },
      // 免费课程单页面7
      {
        path: '/Gratis7',
        name: 'Gratis7',
        component: () => import('@/views/gratis/Gratis7.vue')
      },
      // 免费课程单页面8
      {
        path: '/Gratis8',
        name: 'Gratis8',
        component: () => import('@/views/gratis/Gratis8.vue')
      },
      // 免费课程单页面9
      {
        path: '/Gratis9',
        name: 'Gratis9',
        component: () => import('@/views/gratis/Gratis9.vue')
      },
      // 免费课程单页面10
      {
        path: '/Gratis10',
        name: 'Gratis10',
        component: () => import('@/views/gratis/Gratis10.vue')
      },
      // 免费课程单页面11
      {
        path: '/Gratis11',
        name: 'Gratis11',
        component: () => import('@/views/gratis/Gratis11.vue')
      },
      // 免费课程单页面1
      {
        path: '/Gratis12',
        name: 'Gratis12',
        component: () => import('@/views/gratis/Gratis12.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  devtools: true
  // 路由配置...
})

export default router
